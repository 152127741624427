/* only small desktops */
/* tablets */
/* only small tablets */
 @media only screen and (min-width: 992px) and (max-width: 1199px) {
     nav.navbar.bootsnav ul.nav > li > a{
         padding-top: 10px;
		 padding-bottom: 10px;
		 padding-left: 10px;
		 padding-right: 30px;
    }
}
 @media (min-width: 768px) and (max-width: 991px) {
     nav.navbar.bootsnav .navbar-header{
         width: 100%;
    }
     .navbar-light .navbar-toggler{
         border-color: #b0b435;
         color: #010101;
         border-radius: 0px;
         margin-right: 7px;
         padding-top: 6px;
         padding-top: 6px;
         float: left;
         margin-top: 20px;
    }
     nav.navbar.bootsnav ul.nav > li > a{
         padding: 5px 15px;
    }
     nav.navbar.bootsnav .navbar-nav{
         padding: 0px;
         margin: 0px;
    }
     .custom-select-box{
         display: none;
    }
     .attr-nav{
         top: 10px;
    }
     nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu{
         position: initial !important;
         top: 0px;
    }
     nav.bootsnav .megamenu-content .col-menu{
         -ms-flex: 0 0 100%;
         flex: 0 0 100%;
         max-width: 100%;
    }
     .col-menu .title{
         font-size: 16px;
    }
     .product-item-filter p{
         float: none;
    }
     .product-item-filter{
         margin: 30px 0px;
    }
     .product-categorie-box{
         margin: 30px 0px;
    }
     .toolbar-sorter-right .bootstrap-select.form-control:not([class*="col-"]){
         width: 70%;
    }
     .add-comp a{
         margin-bottom: 30px;
    }
     .add-to-btn .share-bar{
         float: none;
    }
     .contact-info-left{
         margin-bottom: 30px;
    }
     .right-phone-box{
         display: none;
    }
}
/* mobile or only mobile */
 @media (max-width: 767px) {
     nav.navbar.bootsnav .navbar-header{
         width: 80%;
    }
     .navbar-light .navbar-toggler{
         border-color: #b0b435;
         color: #010101;
         border-radius: 0px;
         margin-right: 7px;
         padding-top: 6px;
         padding-bottom: 6px;
         float: left;
         margin-top: 28px;
    }
     nav.navbar.bootsnav ul.nav > li > a{
         padding: 5px 15px;
    }
     nav.navbar.bootsnav .navbar-nav{
         padding: 0px;
         margin: 0px;
    }
     .cover-slides h1{
         font-size: 28px;
    }
     .cover-slides p{
         font-size: 16px;
    }
     .cover-slides p a{
         font-size: 18px;
    }
     .offer-box li i{
         font-size: 18px;
    }
     .offer-box li{
         font-size: 12px;
    }
     .custom-select-box{
         display: none;
    }
     .right-phone-box, .our-link{
         float: none;
         text-align: center;
    }
     .filter-button-group button{
         font-size: 14px;
         padding: 5px 18px;
		 margin-bottom: 3px;
    }
     .title-blog h3{
         font-size: 16px;
    }
     .footer-widget{
         margin-bottom: 30px;
    }
     .footer-link{
         margin-bottom: 30px;
    }
     nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu{
         position: initial !important;
         top: 0px;
    }
     .col-menu .title{
         font-size: 16px;
    }
     nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu > li > a:hover{
         color: #b0b435;
    }
     .hover-team{
         margin-bottom: 30px;
    }
     .product-categori{
         margin-bottom: 30px;
    }
     .product-item-filter .toolbar-sorter-right{
         width: 100%;
    }
     .product-item-filter p{
         float: none;
    }
     .product-item-filter{
         margin: 30px 0px;
    }
     .product-categorie-box{
         margin: 30px 0px;
    }
     .toolbar-sorter-right .bootstrap-select.form-control:not([class*="col-"]){
         width: 70%;
    }
     .why-text.full-width h4{
         font-size: 20px;
    }
     .coupon-box{
         margin-bottom: 30px;
    }
     .update-box input[type="submit"]{
         margin-right: 0px;
    }
     .single-product-slider{
         margin-bottom: 30px;
    }
     .single-product-details h2{
         font-size: 18px;
    }
     .add-comp a{
         margin-bottom: 30px;
    }
     .add-to-btn .share-bar{
         float: none;
    }
     .contact-info-left{
         margin-bottom: 30px;
    }
     .attr-nav{
         top: 15px;
    }
     .right-phone-box{
         display: none;
    }
	.text-slid-box{
		display: none;
	}
	.login-box{
		float: none;
		text-align: center;
		margin: 0 auto;
	}
	.offer-box-products{
		margin-bottom: 30px;
	}
}
 @media only screen and (min-width: 280px) and (max-width: 599px) {
    .jkkjkj{
        width: 58% !important;
    }
    
    /* .mobile-search1{
        display: block !important;
    } */
    .logo img{
        /* height: 45px !important;
    object-fit: cover !important;
    width: 75px !important; */
    height: 47px !important;
    object-fit: cover !important;
    width: 133px !important;
    margin-left:  35%;
    }
    .Search1{
        display: block !important;
    }
   
    .quantity-centered{
        margin: auto;
      }
      
     .navbar-light .navbar-toggler{
         border-color: #b0b435;
         color: #010101;
         border-radius: 0px;
         margin-right: 7px;
         padding-top: 6px;
		 padding-bottom: 6px;
         top: -14px;
    position: relative;

    }
     nav.navbar.bootsnav ul.nav > li > a{
         padding: 5px 15px;
    }
     nav.navbar.bootsnav .navbar-nav{
         padding: 0px;
         margin: 0px;
    }
     .cover-slides h1{
         font-size: 28px;
    }
     .cover-slides p{
         font-size: 16px;
    }
     .cover-slides p a{
         font-size: 18px;
    }
     .offer-box li i{
         font-size: 11px;
    }
     .offer-box li{
         font-size: 9px;
    }
     .custom-select-box{
         display: none;
    }
     .right-phone-box, .our-link{
         float: none;
         text-align: center;
    }
     .filter-button-group button{
         font-size: 14px;
         padding: 5px 18px;
		 margin-bottom: 3px;
    }
     .title-blog h3{
         font-size: 16px;
    }
     .footer-widget{
         margin-bottom: 30px;
    }
     .footer-link{
         margin-bottom: 30px;
    }
     nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu{
         position: initial !important;
         top: 0px;
    }
     .col-menu .title{
         font-size: 16px;
    }
     nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu > li > a:hover{
         color: #b0b435;
    }
     .hover-team{
         margin-bottom: 30px;
    }
     .product-categori{
         margin-bottom: 30px;
    }
     .product-item-filter .toolbar-sorter-right{
         width: 100%;
    }
     .product-item-filter p{
         float: none;
    }
     .product-item-filter{
         margin: 30px 0px;
    }
     .product-categorie-box{
         margin: 30px 0px;
    }
     .toolbar-sorter-right .bootstrap-select.form-control:not([class*="col-"]){
         width: 70%;
    }
     .why-text.full-width h4{
         font-size: 20px;
    }
     .coupon-box{
         margin-bottom: 30px;
    }
     .update-box input[type="submit"]{
         margin-right: 0px;
    }
     .single-product-slider{
         margin-bottom: 30px;
    }
     .single-product-details h2{
         font-size: 18px;
    }
     .add-comp a{
         margin-bottom: 30px;
    }
     .add-to-btn .share-bar{
         float: none;
    }
     .contact-info-left{
         margin-bottom: 30px;
    }
     .right-phone-box{
         display: none;
    }
	.text-slid-box{
		display: none;
	}
	.login-box{
		float: none;
		text-align: center;
		margin: 0 auto;
	}
	.offer-box-products{
		margin-bottom: 30px;
	}
	.side-menu p{
		display: none;
	}
}
.btn-hover-change:hover{
    background-color: white !important;
    text-decoration: none !important;
}
.popup-container {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999999;
}

@media only screen and (min-width: 292px) and (max-width: 799px) {
    .popup-content {
        width: 90% !important;
        margin: 40% auto;
    }
}

.popup-content {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0,0,0,.5);
    height: auto;
    position: relative;
    width: 50%;
    padding: 20px;
    margin: 5% auto;
}
.close-icon {
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    right: 10px;
    top: 0;
}
